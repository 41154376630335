export interface TableMetrics {
  q10_fees_apy: number;
  median_fees_apy: number;
  q90_fees_apy: number;
  q10_IL_apy: number;
  median_IL_apy: number;
  q90_IL_apy: number;
  range: string;
}

const MetricsTable = (props: TableMetrics) => {
  const {
    q10_fees_apy,
    median_fees_apy,
    q90_fees_apy,
    q10_IL_apy,
    median_IL_apy,
    q90_IL_apy,
    range
  } = props;

  const columnNames = ['Indicator', 'First Decile', 'Median', 'Last Decile'];

  const tableInfos = [
    {
      rowName: 'Fees APY',
      values: [
        q10_fees_apy,
        median_fees_apy,
        q90_fees_apy,
      ],
    },
    {
      rowName: 'Impermanent Loss APY',
      values: [
        q10_IL_apy,
        median_IL_apy,
        q90_IL_apy,
      ],
    },
  ];

  return (
    <div>
      <div className="relative overflow-x-auto rounded-lg shadow-md">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th align="center" colSpan={4} className="py-2 text-white bg-gray-700">
                {range}
              </th>
            </tr>
            <tr>
              {columnNames.map((columnName, index) => {
                return (
                  <th scope="col" className="px-6 py-3 bg-gray-200" key={index}>
                    {columnName}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tableInfos.map((tableInfo, index) => {
              return (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={index}>
                  <th
                    key={index}
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    {tableInfo.rowName}
                  </th>
                  {tableInfo.values.map((value, index) => {
                    return (
                      <th
                        key={index}
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                      >
                        {typeof value === 'string'
                          ? 'N/A'
                          : `${(value * 100).toFixed(2)}%`}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MetricsTable;
