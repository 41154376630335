import { TwoLinesChart } from 'components/Charts/TwoLinesChart';
import { InputChartData } from 'components/Charts/TwoLinesChart/TwoLinesChart';
import { MetricsTable } from 'components/MetricsTable';
import { TableMetrics } from 'components/MetricsTable/MetricsTable';

interface ChartAndTables {
  pairTickers: string;
  chartData: InputChartData;
  shortTermTableData: TableMetrics;
  longTermTableData: TableMetrics;
}

const ChartAndTablesLayout = (props: ChartAndTables) => {
  const { pairTickers, chartData, shortTermTableData, longTermTableData } =
    props;

  return (
    <div className="pt-10">
      <div className="pb-5">
        <p className="text-2xl font-semibold text-center text-white font-montserrat">
          {pairTickers}
        </p>
      </div>
      <div className="max-w-2xl m-auto">
        <TwoLinesChart
          monthly_APY={chartData.monthly_APY}
          monthly_IL_APY={chartData.monthly_IL_APY}
        />
      </div>
      <div className="justify-between pt-5 lg:flex">
        <div className="pt-2 lg:pr-2">
          <MetricsTable
            q10_fees_apy={shortTermTableData.q10_fees_apy}
            median_fees_apy={shortTermTableData.median_fees_apy}
            q90_fees_apy={shortTermTableData.q90_fees_apy}
            q10_IL_apy={shortTermTableData.q10_IL_apy}
            median_IL_apy={shortTermTableData.median_IL_apy}
            q90_IL_apy={shortTermTableData.q90_IL_apy}
            range={shortTermTableData.range}
          />
        </div>
        <div className="pt-2 lg:pl-2">
          <MetricsTable
            q10_fees_apy={longTermTableData.q10_fees_apy}
            median_fees_apy={longTermTableData.median_fees_apy}
            q90_fees_apy={longTermTableData.q90_fees_apy}
            q10_IL_apy={longTermTableData.q10_IL_apy}
            median_IL_apy={longTermTableData.median_IL_apy}
            q90_IL_apy={longTermTableData.q90_IL_apy}
            range={longTermTableData.range}
          />
        </div>
      </div>
      <div className="w-full pb-10 mt-10 border-t-2 border-gray-200" />
    </div>
  );
};

export default ChartAndTablesLayout;
