import { WalletButton } from 'components/WalletButton';
import { ChangeEvent } from 'react';

import { Dropdown } from 'components/Dropdown';
import { ethers } from 'ethers';

export enum AvailableTicker {
  FETH = 'FETH',
  FUSD = 'FUSD'
}

export enum AvailableTickerDecimals {
  FETH = 18,
  FUSD = 18
}

export enum AvailableLpTicker {
  AKIU_FETH = 'AKIU-FETH',
  AKIU_FUSD = 'AKIU-FUSD'
}

interface AccumulatorComponentProps {
  ticker?: AvailableTicker
  lpTicker?: AvailableLpTicker
  account: string | null
  accumulatorContractAddress?: string
  underlyingTokenContractAddress?: string
  tokenAmount?: string
  tokenDecimals?: string
  lpTokenDecimals?: string
  tokenBalance?: string
  lpTokenBalance?: string
  isLoading: boolean
  onChangeToken: (ticker: AvailableTicker) => void
  onChangeTokenAmount: (eventTokenAmount: ChangeEvent<HTMLInputElement>) => void
  onClickDepositAccumulator: () => void
  onClickRedeemAccumulator: () => void
}

function truncate(number_as_string: string, index: number): string {
  return number_as_string.slice(0, (number_as_string.indexOf('.')) + (index + 1));
}

const AccumulatorComponent = (props: AccumulatorComponentProps) => {

  const { ticker, lpTicker, tokenAmount, tokenDecimals, lpTokenDecimals, tokenBalance, lpTokenBalance, account, isLoading, onChangeToken, onChangeTokenAmount, onClickDepositAccumulator, onClickRedeemAccumulator } = props;
  
  if (!window.ethereum) {
    return <p>c</p>;
  }

  return (
    <div className="flex justify-center px-4 pt-8">
      <div className="px-6 py-6 bg-white shadow-lg rounded-xl ">
        <div className="flex justify-center pb-4">
	        <p className="text-2xl font-semibold text-center text-dark-blue font-montserrat">Accumulator</p>
        </div>
        <div className="justify-center pb-4 lg:flex">
          <div className="flex justify-center text-xl font-montserrat">
            <input onChange={(e)=> onChangeTokenAmount(e)} placeholder="0.0" className="px-2 py-2" type="number" min="0" />
          </div>
          <div className="flex justify-center pt-2 pl-2 lg:pt-0">
            <Dropdown
              ticker={ticker}
              onChangeToken={(ticker) => onChangeToken(ticker)}
            />
          </div>
        </div>
        <div className="flex justify-center pt-4">
          { account ? 
            (
              <div className=''>
                <div className='flex justify-center'>
                  <button
                    onClick={() => onClickDepositAccumulator()}
                    disabled={!tokenAmount || !ticker || isLoading}
                    className="px-4 py-2 font-bold text-white border-2 cursor-pointer border-bubble-gum-saturated hover:border-blue-400 bg-bubble-gum-saturated font-montserrat rounded-2xl font-3xl hover:bg-blue-400">
                    {isLoading ? 'Loading...': `Deposit ${tokenAmount || ''} ${ticker || ''}`}
                  </button>
                </div>
                <div className='pt-4'>
                  <div className='flex justify-center'>
                    <button
                      onClick={() => onClickRedeemAccumulator()}
                      disabled={!tokenAmount || !ticker || isLoading}
                      className="px-4 py-2 font-semibold border-2 cursor-pointer text-bubble-gum-saturated hover:text-blue-400 border-bubble-gum-saturated hover:border-blue-400 font-montserrat rounded-2xl font-3xl ">
                      {isLoading ? 'Loading...': `Redeem ${tokenAmount || ''} ${lpTicker || ''}`}
		                </button>
                  </div>
                  <div className='flex pt-3 justify-right'>
                    <p className='text-sm text-blue-700 font-montserrat'>
		                  {tokenBalance || lpTokenBalance ? 'Wallet balances:' : ''} <br></br>
                      {tokenBalance ? `${truncate(ethers.utils.formatUnits(tokenBalance, tokenDecimals), 4)} ${ticker}` : ''} <br></br>
		                  {lpTokenBalance ? `${truncate(ethers.utils.formatUnits(lpTokenBalance, lpTokenDecimals), 4)} ${lpTicker}` : ''}
                    </p>
                  </div>
                </div>
              </div>
            )
            :
            <WalletButton />
          }
        </div>
      </div>
    </div>
  );
};

export default AccumulatorComponent;