import { Chart as ChartJS, ChartOptions, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment';
import getDataForTwoLinesChart from 'utils/getDataForTwoLinesChart';
ChartJS.register(...registerables);

export interface InputChartData {
  monthly_APY: { [key: string]: number };
  monthly_IL_APY: { [key: string]: number };
}

const TwoLinesChart = (props: InputChartData) => {
  const monthlyApy = props.monthly_APY;
  const monthlyImpermanentLoss = props.monthly_IL_APY;
  const apyData = getDataForTwoLinesChart(monthlyApy);
  const impermanentLossData = getDataForTwoLinesChart(monthlyImpermanentLoss);

  const chartOptions: ChartOptions<'line'> = {
    scales: {
      x: {
        type: 'time' as const,
        time: {
          unit: 'month' as const,
        },
      },
      y: {
        ticks: {
          callback: (value) => {
            return `${Number(value).toFixed(1)}%`;
          }
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${context.formattedValue}%`;
          }
        }
      }
    }
  };

  const data = {
    datasets: [
      {
        label: 'APY',
        data: apyData,
        borderColor: 'rgb(255, 72, 165)',
        backgroundColor: 'rgb(255, 72, 165)',
        tension: 0.1,
        pointRadius: 0,
      },
      {
        label: 'Impermanent loss',
        data: impermanentLossData,
        borderColor: 'rgb(81, 96, 120)',
        backgroundColor: 'rgb(81, 96, 120)',
        tension: 0.1,
        pointRadius: 0,
      },
    ],
  };

  return <Line data={data} options={chartOptions} />;
};

export default TwoLinesChart;
