const truncateAddress = (address: string | null) => {

  if (!address) {
    return 'N/A';
  }

  const startString = address.substring(0, 4); 
  const endString = address.substring(address.length - 4, address.length); 
  
  return `${startString}…${endString}`;
};

export default truncateAddress;