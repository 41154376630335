import { Accumulators } from 'containers/Accumulators';
import {Mint} from 'containers/Mint';

const AppPageLayout = () => {

  return (
    <div>
      <Accumulators />
      <Mint />
    </div>
  );
};

export default AppPageLayout;