import { ReactElement, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import whitepaperPDF from 'assets/whitepaper/whitepaper.pdf';
import TwitterWhitePng from 'assets/socials/twitter-white.png';
import LinkedinWhitePng from 'assets/socials/linkedin-white.png';
import DiscordWhitePng from 'assets/socials/discord-white.png';
import MediumWhitePng from 'assets/socials/medium-white.png';
import GithubWhitePng from 'assets/socials/github-white.png';
import allLinks from 'utils/allLinks';
import { WalletButton } from 'components/WalletButton';
import { AvailablePage } from 'utils/availablePages';

const NavbarControl = () => {
  const navigate = useNavigate();

  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState<boolean>(false);

  const handleClickHome = () => {
    setIsBurgerMenuOpen(false);
  };

  const handleClickBurgerMenu = () => {
    setIsBurgerMenuOpen(!isBurgerMenuOpen);
  };

  const handleClickLaunchApp = () => {
    navigate(AvailablePage.Accumulator);
  };

  const currentLocationPath = useLocation();
  const isAccumulatorPage = currentLocationPath.pathname == AvailablePage.Accumulator;

  interface INavbarLink {
    name: string;
    activePage?: boolean
    onClickLink?: () => void;
  }

  const NAVBAR_LINKS: INavbarLink[] = [
    {
      name: 'Home',
      activePage: currentLocationPath.pathname === AvailablePage.Homepage,
      onClickLink: () => {
        setIsBurgerMenuOpen(false);
        navigate(AvailablePage.Homepage);
      },
    },
    {
      name: 'Analytics',
      activePage: currentLocationPath.pathname === AvailablePage.Analytics,
      onClickLink: () => {
        setIsBurgerMenuOpen(false);
        navigate(AvailablePage.Analytics);
      },
    },
    {
      name: 'Contact',
      activePage: currentLocationPath.pathname === AvailablePage.Contact,
      onClickLink: () => {
        setIsBurgerMenuOpen(false);
        navigate(AvailablePage.Contact);
      },
    },
    {
      name: 'Whitepaper',
    },
  ];

  const handleClickLogo = () => {
    navigate('/');
  };

  const handleClickLink = (myLink: string) => {
    window.open(myLink, '_blank');
  };


  const getNavbarLink = (navbarLink: INavbarLink): ReactElement => {
    if (navbarLink.name === 'Whitepaper') {
      return (
        <a
          href={whitepaperPDF}
          target="_blank"
          className="px-2 text-xl font-semibold text-white cursor-pointer xl:px-7 font-montserrat hover:text-bubble-gum"
          rel="noreferrer"
        >
          {navbarLink.name}
        </a>
      );
    }

    if (navbarLink.activePage) {
      return (
        <p
          className="px-2 text-xl font-semibold cursor-pointer text-bubble-gum-saturated xl:px-7 font-montserrat hover:text-bubble-gum"
          onClick={() => navbarLink.onClickLink && navbarLink.onClickLink()}
        >
          {navbarLink.name}
        </p>
      );
    }
    
    return (
      <p
        className="px-2 text-xl font-semibold text-white cursor-pointer xl:px-7 font-montserrat hover:text-bubble-gum"
        onClick={() => navbarLink.onClickLink && navbarLink.onClickLink()}
      >
        {navbarLink.name}
      </p>
    );
  };

  return (
    <>
      {/* DESKTOP */}
      <div className="px-2 pt-2 lg:px-6 lg:pt-4 ">
        <div className="items-center justify-between hidden xl:flex">
          <div className="cursor-pointer xl:w-300" onClick={handleClickHome}>
            <div className="flex align-middle">
              <div
                className="px-4 py-2 mr-5 border border-white rounded-full bg-bubble-gum-saturated"
                onClick={handleClickLogo}
              >
                <p className="text-4xl font-semibold text-white uppercase cursor-pointer font-montserrat">
                  AKIU
                </p>
              </div>
              <div className="flex">
                {NAVBAR_LINKS.map((navbarLink, index) => {
                  return (
                    <div
                      className="flex flex-col items-center justify-center"
                      key={index}
                    >
                      {getNavbarLink(navbarLink)}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div
            className="flex"
          >
            {isAccumulatorPage ? 
              <WalletButton />
              :
              <button
                onClick={handleClickLaunchApp}
                className="px-4 py-2 font-bold text-white border-2 cursor-pointer bg-bubble-gum-saturated font-montserrat rounded-2xl font-3xl hover:bg-blue-400">
                  LAUNCH APP
              </button>
            }
          </div>
        </div>
      </div>
      {/* MOBILE */}
      <div className="px-2 pt-2 lg:px-6 lg:pt-4 xl:hidden">
        <div className="flex items-center justify-between ">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="text-white cursor-pointer w-9 h-9"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              onClick={handleClickBurgerMenu}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </div>
        </div>
        {/* MOBILE MENU */}
        {isBurgerMenuOpen && (
          <div className="items-start xl:hidden">
            {NAVBAR_LINKS.map((navbarLink, index) => {
              return (
                <div key={index}>{getNavbarLink(navbarLink)}</div>
              );
            })}
            <div
              className="flex cursor-pointer"
            >
              {isAccumulatorPage ? 
                <WalletButton />
                :
                <button
                  onClick={handleClickLaunchApp}
                  className="px-4 py-2 font-bold text-white border-2 cursor-pointer bg-bubble-gum-saturated font-montserrat rounded-2xl font-3xl hover:bg-blue-400">
                  LAUNCH APP
                </button>
              }
            </div>
            <div className="flex px-2 mt-2">
              <div
                className="cursor-pointer w-7 h-7"
                onClick={() => handleClickLink(allLinks.linkedinLink)}
              >
                <img src={LinkedinWhitePng} alt="linkedin-logo" />
              </div>
              <div
                className="ml-3 cursor-pointer w-7 h-7"
                onClick={() => handleClickLink(allLinks.twitterLink)}
              >
                <img src={TwitterWhitePng} alt="twitter-logo" />
              </div>
              <div
                className="pt-1 ml-3 cursor-pointer w-7 h-7"
                onClick={() => handleClickLink(allLinks.discordLink)}
              >
                <img src={DiscordWhitePng} alt="discord-logo" />
              </div>
              <div
                className="ml-3 cursor-pointer w-7 h-7"
                onClick={() => handleClickLink(allLinks.githubLink)}
              >
                <img src={GithubWhitePng} alt="github-logo" />
              </div>
              <div
                className="ml-3 cursor-pointer w-7 h-7"
                onClick={() => handleClickLink(allLinks.mediumLink)}
              >
                <img src={MediumWhitePng} alt="medium-logo" />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default NavbarControl;
