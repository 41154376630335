const allLinks = {
  twitterLink: 'https://twitter.com/Akiumulate',
  linkedinLink: 'https://www.linkedin.com/company/akiu/',
  mediumLink: 'https://medium.com/@akiumulate',
  discordLink: 'https://discord.com/invite/UMqtqzSmrN',
  githubLink: 'https://github.com/akiumulate',
  nickLinkedin: 'https://www.linkedin.com/in/niedermowwe/',
  jalalLinkedin: 'https://www.linkedin.com/in/jalal-el-hazzat-004507118/',
  hubertLinkedin: 'https://www.linkedin.com/in/hubert-klaja-78470a146/',
  zukiuLinkedin: '',
  nickTwitter: '',
  jalalTwitter: '',
  hubertTwitter: '',
  zukiuTwitter: ''
};

export default allLinks;
