import { AvailableTicker } from 'components/Accumulator/AccumulatorComponent';
import { Dropdown } from 'components/Dropdown';

interface MintComponentProps {
  tokenTicker?: AvailableTicker
  isLoading: boolean
  tokenAmountToBeMinted?: number
  onChangeToken?: (ticker: AvailableTicker) => void
  onClickMint: () => void
}


const MintComponent = (props: MintComponentProps) => {
  const { tokenTicker, isLoading, tokenAmountToBeMinted, onChangeToken, onClickMint } = props;
  
  return(
    <div className="flex justify-center px-4 pt-12">
      <div className="px-6 py-6 bg-white shadow-lg rounded-xl ">
        <div className="flex justify-center pb-4">
          <div>
	    <p className="text-2xl font-semibold text-center text-dark-blue font-montserrat">Mint tokens</p>
            <p className="text-center text-l text-dark-blue font-montserrat">Mint ERC20 test tokens and deposit them in the Accumulator to earn yield</p>
	    <p className="text-center text-l text-red-400 font-montserrat">Note: the smart contracts are deployed on Arbitrum Rinkeby (chain ID 421611)</p>
	    <p className="text-center text-l text-red-400 font-montserrat">Please make sure that your wallet is connected the correct network</p>
          </div>
        </div>
        <div className="flex justify-center pb-4">
          <Dropdown
            ticker={tokenTicker}
            onChangeToken={(ticker) => onChangeToken && onChangeToken(ticker)}
          />
        </div>
        <div className='flex justify-center'>
          <button
            onClick={() => onClickMint()}
            disabled={ !tokenTicker || isLoading}
            className="px-4 py-2 font-semibold border-2 cursor-pointer text-bubble-gum-saturated hover:text-blue-400 border-bubble-gum-saturated hover:border-blue-400 font-montserrat rounded-2xl font-3xl ">
              Mint {tokenAmountToBeMinted} {tokenTicker} test tokens
		        </button>
        </div>
      </div>
    </div>
  );

};

export default MintComponent;