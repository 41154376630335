import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
  const navigate = useNavigate();
  const handleClickToHomepage = () => {
    navigate('/');
  };

  return (
    <div className="flex justify-center h-screen px-2 py-10">
      <div>
        <p className="pt-5 text-3xl text-center text-white uppercase md:text-7xl font-montserrat">
          Oh no! Something went wrong.
        </p>
        <p className="pt-5 text-3xl text-center text-white uppercase md:text-7xl font-montserrat">
          Go back to the homepage
        </p>
        <button
          className="w-full px-4 py-3 mt-10 text-4xl font-semibold text-white rounded-full cursor-pointer bg-bubble-gum-saturated font-montserrat hover:bg-bubble-gum-saturated"
          onClick={handleClickToHomepage}
        >
          GO TO THE HOMEPAGE
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
