import Uniswap from 'assets/partners/uniswap.png';
import Perpetual from 'assets/partners/perpetual.png';
import Bepop from 'assets/partners/bebop.png';

const Partners = () => {
  return(

    <div className="flex justify-center pt-8 mb-10">
      <div className="max-w-4xl">
        <div className='pt-6'>
          <p className="text-3xl font-semibold text-center text-bubble-gum-saturated font-montserrat">
            POWERED BY:
          </p>
        </div>
        <div>
          <div className='flex justify-center'>
            <img src={Uniswap} alt="partner-uniswap" width={250} />
          </div>
          <div className='flex justify-center'>
            <img src={Perpetual} alt="partner-perpetual" width={200} />
          </div>
          <div className='flex justify-center pt-12'>
            <img src={Bepop} alt="partner-bepop" width={200} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;