import { ChangeEvent, useEffect, useState } from 'react';
import { AccumulatorComponent } from 'components/Accumulator';
import { AvailableTicker, AvailableLpTicker } from 'components/Accumulator/AccumulatorComponent';
import { FethAccumulatorContract, FusdAccumulatorContract } from 'smartContracts/accumulatorsContracts/contractAddresses';
import { FethUnderlyingTokenContract, FusdUnderlyingTokenContract } from 'smartContracts/underlyingTokensContracts/contractAddresses';

import { useMoralis } from 'react-moralis';

import { ethers } from 'ethers';
import UnderlyingTokenContractABI from 'smartContracts/underlyingTokensContracts/abi.json';
import AccumulatorContractABI from 'smartContracts/accumulatorsContracts/abi.json';

const Accumulators = () => {

  const [selectedTokenTicker, setSelectedTokenTicker] = useState<undefined | AvailableTicker>(undefined);
  const [selectedLpTokenTicker, setSelectedLpTokenTicker] = useState<undefined | AvailableLpTicker>(undefined);
  const [selectedAccumulatorContractAddress, setSelectedAccumulatorContractAddress] = useState<undefined | string>(undefined);
  const [selectedUnderlyingTokenContractAddress, setSelectedUnderlyingTokenContractAddress] = useState<undefined | string>(undefined);
  const [tokenAmount, setTokenAmount] = useState<string | undefined>(undefined);
  const [tokenDecimals, setTokenDecimals] = useState<string | undefined>(undefined);  
  const [lpTokenDecimals, setLpTokenDecimals] = useState<string | undefined>(undefined);
  const [tokenBalance, setTokenBalance] = useState<string | undefined>(undefined);  
  const [lpTokenBalance, setLpTokenBalance] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { account } = useMoralis();
  
  if (!window.ethereum) {
    const handleInstallMetamask = () => {
      window.open('https://metamask.io/', '_blank');
    };

    return (
      <div className="flex justify-center px-4 pt-8">
        <div className="px-6 py-6 bg-white shadow-lg rounded-xl ">
          <div className="flex justify-center pb-4">
            <p className="text-2xl font-semibold text-center text-dark-blue font-montserrat">Accumulator</p>
          </div>
          <div className="flex justify-center pb-4">
            <div className="text-xl font-montserrat">
              <input placeholder="Install Metamask" className="px-2 py-2" type="number" min="0" />
            </div>
          </div>
          <div className="flex justify-center pt-4">    
            <div className=''>
              <div className='flex justify-center'>
                <button
                  onClick={handleInstallMetamask}
                  className="px-4 py-2 font-bold text-white border-2 cursor-pointer disabled:opacity-40 border-bubble-gum-saturated hover:border-turquoise bg-bubble-gum-saturated font-montserrat rounded-2xl font-3xl hover:bg-turquoise">
                  Install Metamask to use the dapp
                </button>
              </div>
            </div>   
          </div>
        </div>
      </div>
    );
  }
  
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const underlyingTokenContract = selectedUnderlyingTokenContractAddress ? new ethers.Contract(selectedUnderlyingTokenContractAddress, UnderlyingTokenContractABI, signer) : undefined;
  const accumulatorContract = selectedAccumulatorContractAddress ? new ethers.Contract(selectedAccumulatorContractAddress, AccumulatorContractABI, signer) : undefined;

  const handleTokenAmount = (eventTokenAmount: ChangeEvent<HTMLInputElement>) => {
    const tokenAmount = eventTokenAmount.target.value;
    setTokenAmount(tokenAmount);
  };


  const handleChangeToken = async (ticker: AvailableTicker) => {
    setSelectedTokenTicker(ticker);
    if (ticker === AvailableTicker.FETH) {    
      setSelectedLpTokenTicker(AvailableLpTicker.AKIU_FETH);
      setSelectedAccumulatorContractAddress(FethAccumulatorContract);
      setSelectedUnderlyingTokenContractAddress(FethUnderlyingTokenContract);
      
      return;
    }
    if (ticker === AvailableTicker.FUSD) {
      setSelectedLpTokenTicker(AvailableLpTicker.AKIU_FUSD);
      setSelectedAccumulatorContractAddress(FusdAccumulatorContract);
      setSelectedUnderlyingTokenContractAddress(FusdUnderlyingTokenContract);

      return;
    }
  };



  const handleClickDepositAccumulator = async () => {
    if (!tokenAmount) return;
    if (!underlyingTokenContract) return;
    if (!accumulatorContract) return;
    setIsLoading(true);

    // Check whether wallet holds sufficient tokens for deposit
    let tokenBalance = await underlyingTokenContract.balanceOf(account);
    if (ethers.BigNumber.from(tokenBalance).lt(ethers.utils.parseUnits(tokenAmount, 18))) {
      setIsLoading(false);

      return;
    }

    // Check whether Accumulator is approved to spend token
    const allowedUnderlyingTokenAmountToBeSpent = await underlyingTokenContract.allowance(account, selectedAccumulatorContractAddress); 
    
    if (allowedUnderlyingTokenAmountToBeSpent < tokenAmount) {
      const approvedTokensAmountToBeSpentTransaction = await underlyingTokenContract.approve(selectedAccumulatorContractAddress, ethers.constants.MaxUint256);
      await approvedTokensAmountToBeSpentTransaction.wait();
    }

    const depositedTokensInAccumulatorTransaction = await accumulatorContract.deposit(ethers.utils.parseEther(tokenAmount), account);
    await depositedTokensInAccumulatorTransaction.wait();
    tokenBalance = await underlyingTokenContract.balanceOf(account);
    setTokenBalance(tokenBalance.toString());
    const lpTokenBalance = await accumulatorContract.balanceOf(account);
    setLpTokenBalance(lpTokenBalance.toString());
    setIsLoading(false);
  };

  const handleClickRedeemAccumulator = async () => {
    if (!tokenAmount) return;
    if (!underlyingTokenContract) return;
    if (!accumulatorContract) return;
    setIsLoading(true);

    // Check whether wallet holds sufficient AKIU tokens to redeem
    let lpTokenBalance = await accumulatorContract.balanceOf(account);
    if (ethers.utils.parseUnits(tokenAmount, 18).gt(ethers.BigNumber.from(lpTokenBalance))) {
      setIsLoading(false);
      
      return;
    }

    // Check whether Accumulator is approved to spend AKIU tokens
    const allowedLpTokenAmountToBeSpent = await accumulatorContract.allowance(account, selectedAccumulatorContractAddress); 
    
    if (allowedLpTokenAmountToBeSpent < tokenAmount) {
      const approvedLpTokenAmountToBeSpentTransaction = await accumulatorContract.approve(selectedAccumulatorContractAddress, ethers.constants.MaxUint256);
      await approvedLpTokenAmountToBeSpentTransaction.wait();
    }

    const redeemLPtokenTransaction = await accumulatorContract.redeem(ethers.utils.parseUnits(tokenAmount, 18), account, account);
    await redeemLPtokenTransaction.wait();
    const tokenBalance = await underlyingTokenContract.balanceOf(account);
    setTokenBalance(tokenBalance.toString());
    lpTokenBalance = await accumulatorContract.balanceOf(account);
    setLpTokenBalance(lpTokenBalance.toString());
    setIsLoading(false);

  };

  useEffect( () => {
    if (!underlyingTokenContract) return;
    if (!accumulatorContract) return;
    setIsLoading(true);

    if (account) {
    
      const getTokenBalance = async () => {
        const tokenBalance = await underlyingTokenContract.balanceOf(account);
        setTokenBalance(tokenBalance.toString());
      };
      getTokenBalance();
      
      const getLpTokenBalance = async () => {
        const lpTokenBalance = await accumulatorContract.balanceOf(account);
        setLpTokenBalance(lpTokenBalance.toString());
      };
      getLpTokenBalance();

      const getTokenDecimals = async () => {
        const tokenDecimals = await underlyingTokenContract.decimals();
        setTokenDecimals(tokenDecimals.toString());
      };
      getTokenDecimals();
      
      const getLpTokenDecimals = async () => {
        const lpTokenDecimals = await accumulatorContract.decimals();
        setLpTokenDecimals(lpTokenDecimals.toString());
      };
      getLpTokenDecimals();
      
    }
    setIsLoading(false);

  
  }, [selectedTokenTicker]);



  return (
    <AccumulatorComponent
      ticker={selectedTokenTicker}
      lpTicker={selectedLpTokenTicker}
      tokenAmount={tokenAmount}
      tokenDecimals={tokenDecimals}
      lpTokenDecimals={lpTokenDecimals}
      tokenBalance={tokenBalance}
      lpTokenBalance={lpTokenBalance}
      account={account}
      isLoading={isLoading}
      accumulatorContractAddress={selectedAccumulatorContractAddress}
      underlyingTokenContractAddress={selectedUnderlyingTokenContractAddress}
      onChangeToken={(ticker) => handleChangeToken(ticker)}
      onChangeTokenAmount={(tokenAmount) => handleTokenAmount(tokenAmount)}
      onClickDepositAccumulator={handleClickDepositAccumulator}
      onClickRedeemAccumulator={handleClickRedeemAccumulator}
    />
  );
};

export default Accumulators;