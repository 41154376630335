import TwitterWhitePng from 'assets/socials/twitter-white.png';
import LinkedinWhitePng from 'assets/socials/linkedin-white.png';
import DiscordWhitePng from 'assets/socials/discord-white.png';
import GithubWhitePng from 'assets/socials/github-white.png';
import whitepaperPDF from 'assets/whitepaper/whitepaper.pdf';


import allLinks from 'utils/allLinks';
import { useNavigate } from 'react-router-dom';
import { AvailablePage } from 'utils/availablePages';

const Footer = () => {
  const navigate = useNavigate();
  const handleClickLink = (myLink: string) => {
    window.open(myLink, '_blank');
  };

  

  const handleClickContact = () => {
    navigate(AvailablePage.Contact);
  };

  return (
    <div className="px-12 pt-3 pb-3 mt-4 bg-dark-blue">
      <div className="flex justify-between">
        <div className="lg:flex">
          <div className="lg:px-4">
            <a
              href={whitepaperPDF}
              target="_blank"
              className="text-white cursor-pointer text-m font-montserrat hover:text-bubble-gum"
              rel="noreferrer"
            >
              Whitepaper
            </a>
          </div>
          <div onClick={()=>handleClickLink(allLinks.mediumLink)} className="lg:pr-6 lg:pl-2">
            <p className="text-white cursor-pointer text-m font-montserrat hover:text-bubble-gum">
            Medium
            </p>
          </div>

          <div onClick={handleClickContact}>
            <p className="text-white cursor-pointer text-m font-montserrat hover:text-bubble-gum">
              info@akiu.io
            </p>
          </div>
        </div>
        <div>
          <div className="flex justify-between">
            <div>
              <div
                className="w-6 h-6 mb-2 ml-8 cursor-pointer"
                onClick={() => handleClickLink(allLinks.linkedinLink)}
              >
                <img src={LinkedinWhitePng} alt="instagram" />
              </div>
              <div
                className="w-6 h-6 pt-1 mx-8 cursor-pointer"
                onClick={() => handleClickLink(allLinks.discordLink)}
              >
                <img src={DiscordWhitePng} alt="discord" />
              </div>
            </div>
            <div>
              <div
                className="w-6 h-6 mb-2 mr-5 cursor-pointer"
                onClick={() => handleClickLink(allLinks.twitterLink)}
              >
                <img src={TwitterWhitePng} alt="twitter" />
              </div>
              <div
                className="w-6 h-6 cursor-pointer"
                onClick={() => handleClickLink(allLinks.githubLink)}
              >
                <img src={GithubWhitePng} alt="github" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center pt-8">
        <div>
          <p className="text-white text-m font-montserrat">
            © Copyright 2022. All rights reserverd. AKIU
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
