import { useMoralis } from 'react-moralis';
import truncateAddress from 'utils/truncateAddress';
import MetamaskImage from 'assets/cryptos/metamaskPng.png';
import { useEffect } from 'react';

const getNetworkNameFromChainId = (chainId: number) => {
  switch(chainId) {
  //case 1:
  //  return 'Ethereum  mainnet';
  //case 3:
  //  return 'Ropsten testnet';
  //case 4:
  //  return 'Rinkeby testnet';
  //case 42161:
  //  return 'Arbitrum One';
  case 421611:
    return ['Arbitrum Rinkeby', '', ''];
  default:
    return ['Network not supported!', 'Please connect your wallet', 'to Arbitrum Rinkeby'];
  }
}; 

const WalletButton = () => {
  const { enableWeb3, account, isWeb3Enabled, Moralis, deactivateWeb3, isWeb3EnableLoading, web3 } = useMoralis();
  
  const handleConnectMetamask = async () => {
    await enableWeb3();
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('connected', 'injected');
    }
  };

  useEffect(() => {
    if (isWeb3Enabled) return;
    if (typeof window !== 'undefined') {
      if (window.localStorage.getItem('connected')) {
        enableWeb3();
      }
    }
  }, [isWeb3Enabled]);

  useEffect(() => {
    Moralis.onAccountChanged((account) => {
      if (account === null) {
        window.localStorage.removeItem('connected');
        deactivateWeb3();
      }
    });
  }, []);

  const currentNetworkId = web3?.network.chainId || 1;
  const networkName = getNetworkNameFromChainId(currentNetworkId);

  return(
    <div>
      {
        !account ? (
          <button
            disabled={isWeb3EnableLoading}
            onClick={() => handleConnectMetamask()}
            className="px-4 py-2 font-bold text-white border-2 cursor-pointer bg-bubble-gum-saturated font-montserrat rounded-2xl font-3xl hover:bg-blue-400">
              CONNECT METAMASK
          </button>
        ) : (
          <div>
            <button
              className="px-4 py-2 font-bold text-white border-2 bg-bubble-gum-saturated font-montserrat rounded-2xl font-3xl">
              <div className="flex">
                <div className="pr-2">
                  <img src={MetamaskImage} alt="metamask-logo" width={32} />
                </div>
                <div className="flex items-center justify-center">
                  <p className="font-montserrat">
                    {truncateAddress(account)}
                  </p>
                </div>
              </div>
            </button>
            <div className='flex pt-1 pr-1 lg:justify-center'>
	      <p className="text-sm font-semibold text-left text-green-700 font-montserrat">
	        {networkName[0]} <br></br>
                {networkName[1]} <br></br>
                {networkName[2]} <br></br>
	      </p>
            </div>
          </div>
        )}
    </div>
    

  );
};

export default WalletButton;