import { useState } from 'react';
import faunadb from 'faunadb';
import validateEmail from 'utils/validateEmail';
const client = new faunadb.Client({
  secret: process.env.REACT_APP_FAUNA_DB || '',
  scheme: 'https',
  domain: 'db.fauna.com',
});

const q = faunadb.query;

const Contact = () => {
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [subject, setSubject] = useState<string | undefined>(undefined);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [isCheckboxSelected, setIsCheckboxSelected] = useState<boolean>(true);
  const [isMessageSent, setIsMessageSent] = useState<boolean>(false);
  const [isMessageFailed, setIsMessageFailed] = useState<boolean>(false);

  const messageSentSuccessfully = () => {
    setIsMessageSent(true);
    setEmail(undefined);
    setSubject(undefined);
    setMessage(undefined);
    setIsCheckboxSelected(true);
  };

  const createContactMessage = (email: string, subject: string, message: string, isCheckboxSelected: boolean) => {
    client
      .query(
        q.Create(q.Collection('ContactForm'), {
          data: {
            email: email,
            subject: subject, 
            message: message,
            isCheckboxSelected: isCheckboxSelected  
          }
        })
      )
      .then(() => messageSentSuccessfully())
      .catch(() => setIsMessageFailed(true));
  };

  const handleChangeEmail = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEmail(e.target.value);
  };
  const handleChangeSubject = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSubject(e.target.value);
  };
  const handleChangeMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const handleClickCheckbox = () => {
    setIsCheckboxSelected(!isCheckboxSelected);
  };

  const handleClickSubmitButton = () => {
    if (email && subject && message && isCheckboxSelected) {
      createContactMessage(email, subject, message, isCheckboxSelected);
    }
  };

  const isFormValid = email && subject && message && validateEmail(email) ? true : false;

  return (
    <div className="pb-20">
      <div className="pt-10 pb-16">
        <p className="text-5xl font-semibold text-center text-white font-montserrat">
          CONTACT US
        </p>
      </div>
      <div className="py-8 rounded bg-dark-blue">
        <div>
          <div className="pb-2">
            <p className="text-xl font-semibold text-center text-white font-montserrat">
              Email*
            </p>
          </div>
          <div className="flex justify-center">
            <div>
              <textarea
                rows={1}
                onChange={(e) => handleChangeEmail(e)}
                className="px-2 text-xl text-gray-800 rounded font-montserrat"
              />
            </div>
          </div>
        </div>
        <div className="py-8">
          <div className="pb-2">
            <p className="text-xl font-semibold text-center text-white font-montserrat">
              Subject*
            </p>
          </div>
          <div className="flex justify-center">
            <div>
              <textarea
                rows={1}
                onChange={(e) => handleChangeSubject(e)}
                className="px-2 text-xl text-gray-800 rounded font-montserrat"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="pb-2">
            <p className="text-xl font-semibold text-center text-white font-montserrat">
              Message*
            </p>
          </div>
          <div className="flex justify-center">
            <div>
              <textarea
                rows={10}
                onChange={(e) => handleChangeMessage(e)}
                className="px-2 text-xl text-gray-800 rounded inuput font-montserrat"
              />
            </div>
          </div>
          <div className="flex justify-center">
            <div>
              <p className="pr-2 font-semibold text-center text-white text-l font-montserrat">
                Receive newsletter
              </p>
            </div>
            <div>
              <input
                type="checkbox"
                onChange={handleClickCheckbox}
                checked={isCheckboxSelected}
              />
            </div>
          </div>
          <div className="flex justify-center pt-3">
            <div>
              <button
                disabled={!isFormValid}
                onClick={() => handleClickSubmitButton()}
                className="px-4 py-2 font-semibold text-white border cursor-pointer bg-bubble-gum-saturated hover:bg-blue-400 font-montserrat rounded-2xl font-3xl"
              >
                SEND
              </button>
              
            </div>
          </div>
          <div className='flex justify-center'>
            {isMessageSent && (
              <div>
                <p className='text-green-700 font-3xl'>Message sent!</p>
              </div>
            )}
            {isMessageFailed && (
              <div>
                <p className='text-red-700 font-3xl'>Message error, try again</p>
              </div>)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
