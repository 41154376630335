import { useState } from 'react';
import { useMoralis } from 'react-moralis';

import { ethers } from 'ethers';
import { AvailableTicker } from 'components/Accumulator/AccumulatorComponent';
import { AvailableTickerDecimals } from 'components/Accumulator/AccumulatorComponent';
import { MintComponent } from 'components/Mint';
import { FethUnderlyingTokenContract, FusdUnderlyingTokenContract } from 'smartContracts/underlyingTokensContracts/contractAddresses';
import UnderlyingTokenContractABI from 'smartContracts/underlyingTokensContracts/abi.json';
import getNetworkNameFromChainId, { AvailableChain } from 'utils/getNetworkNameFromChainId';

const Mint = () => {

  if (!window.ethereum) {
    return null;
  }
  const { web3 } = useMoralis();

  const currentNetworkId = web3?.network.chainId || 1;

  const currentChainName = getNetworkNameFromChainId(currentNetworkId);

  if (currentChainName !== AvailableChain.ArbitrumRinkeby ) return null;

  const [selectedTokenToBeMinted, setSelectedTokenToMinted] = useState<undefined | AvailableTicker>(undefined);
  const [tokenAmountToBeMinted, setTokenAmountToBeMinted] = useState<undefined | number>(undefined);
  const [tokenDecimalsToBeMinted, setTokenDecimalsToBeMinted] = useState<undefined | number>(undefined);
  const [contractAddressTokenToBeMinted, setContractAddressTokenToBeMinted] = useState<undefined | string>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const DEFAULT_MINT_FUSD = 10000;
  const DEFAULT_MINT_FETH = 10;

  const handleTokenChange = (selectedToken: AvailableTicker) => {
    if (selectedToken === AvailableTicker.FETH) {
      setTokenAmountToBeMinted(DEFAULT_MINT_FETH);
      setTokenDecimalsToBeMinted(AvailableTickerDecimals.FETH);
      setContractAddressTokenToBeMinted(FethUnderlyingTokenContract);
      setSelectedTokenToMinted(AvailableTicker.FETH);
      
      return;
    }

    if (selectedToken === AvailableTicker.FUSD) {
      setTokenAmountToBeMinted(DEFAULT_MINT_FUSD);
      setTokenDecimalsToBeMinted(AvailableTickerDecimals.FUSD);
      setContractAddressTokenToBeMinted(FusdUnderlyingTokenContract);
      setSelectedTokenToMinted(AvailableTicker.FUSD);
      
      return;
    }
    

  };

  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const mintContract = contractAddressTokenToBeMinted ? new ethers.Contract(contractAddressTokenToBeMinted, UnderlyingTokenContractABI, signer) : undefined;

  const handleClickMint = async () => {
    if (!selectedTokenToBeMinted || !contractAddressTokenToBeMinted || !mintContract || !tokenAmountToBeMinted || !tokenDecimalsToBeMinted) return;
    setIsLoading(true);
    const mintTokenTransaction = await mintContract.mint(ethers.utils.parseUnits(tokenAmountToBeMinted.toString(), tokenDecimalsToBeMinted));
    await mintTokenTransaction.wait();
    setIsLoading(false);
  };

  return (
    <MintComponent
      tokenTicker={selectedTokenToBeMinted}
      isLoading={isLoading}
      tokenAmountToBeMinted={tokenAmountToBeMinted}
      onChangeToken={handleTokenChange}
      onClickMint={handleClickMint}
    />
  );

};

export default Mint;