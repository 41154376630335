import { BrowserRouter as Router } from 'react-router-dom';
import { Footer } from 'sections/Footer';
import { Navbar } from './containers/Navbar';
import { Page } from './containers/Page';
import ScrollToTop from 'utils/strollToTop';
import { MoralisProvider } from 'react-moralis';

const App = () => {
  return (
    <MoralisProvider initializeOnMount={false}>
      <Router>
        <div className="flex flex-col min-h-screen gradient">
          <ScrollToTop />
          <div>
            <Navbar />
          </div>
          <div className='flex-grow'>
            <Page />
          </div>
          <div>
            <Footer />
          </div>
        </div>
      </Router>
    </MoralisProvider>
  );
};

export default App;
