import { Main } from '../Main';
import { Routes, Route } from 'react-router-dom';
import { Analytics } from 'containers/Analytics';
import { Whitepaper } from 'containers/Whitepaper';
import { Contact } from 'containers/Contact';
import { ErrorPage } from 'containers/ErrorPage';
import { Institutions } from 'containers/Institutions';
import { AvailablePage } from 'utils/availablePages';
import { AppPageLayout } from 'layouts/AppPage';

const Page = () => {
  return (
    <Routes>
      <Route path={AvailablePage.Homepage} element={<Main />} />
      <Route path={AvailablePage.Accumulator} element={<AppPageLayout />} />
      <Route path={AvailablePage.Analytics} element={<Analytics />} />
      <Route path={AvailablePage.Whitepaper}element={<Whitepaper />} />
      <Route path={AvailablePage.Contact} element={<Contact />} />
      <Route path={AvailablePage.Institutions} element={<Institutions />} />
      <Route path="/*" element={<ErrorPage />} />
    </Routes>
  );
};

export default Page;
