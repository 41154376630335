const Institutions = () => {

  interface InstitutionsInfosProps {
    title: string;
    content: string;
  }

  const institutionsInfos: InstitutionsInfosProps[] = [
    {
      title: 'CRYPTO LENDERS',
      content: 'Earn yield in a few clicks. Non-custodial and without counterparty risk, Akiu is a safe and simple way to access DeFi yields at scale.',
    },
    {
      title: 'HEDGE FUNDS',
      content: 'Leverage our infrastructure when investing in the crypto market. Audited and gas-optimized smart contracts and a suite of analytics tools reduce your operational overheads, allowing you to focus on your core business.',
    },
    {
      title: 'EMERGING MANAGERS',
      content: 'Looking to build a track record? By using Akiu’s smart contracts, you can get started trading immediately while building an auditable track record. Costs are minimal compared to traditional approaches.',
    },
    {
      title: 'TOKEN TREASURIES',
      content: 'Support your community with the liquidity they deserve. Our liquidity-as-a-service product can be customized to your needs.',
    },
  ];

  const InstitutionInfoCard = (props: InstitutionsInfosProps) => {
    const { title, content } = props;

    return (
      <div className="flex flex-col h-full px-8 py-8 mb-4 rounded bg-dark-blue">
        <div className="pb-4">
          <p className="text-xl font-semibold text-center text-bubble-gum-saturated font-montserrat">
            {title}
          </p>
        </div>
        <div className="pt-2 pb-4">
          <p className="text-white text font-montserrat text-l">
            {content}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="flex justify-center pt-24 mb-10">
      <div className="max-w-4xl">
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
          {institutionsInfos.map((institutionInfo, index) => {
            return (
              <div key={index} className="px-4 lg:px-0">
                <InstitutionInfoCard
                  title={institutionInfo.title}
                  content={institutionInfo.content}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Institutions;