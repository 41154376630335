import React from 'react';
import { Column, useTable } from 'react-table';
import scrollTo from 'utils/scrollTo';

export interface DataTable {
  col0?: string
  col1?: string
  col2?: string
  col3?: string
  col4?: string
  col5?: string
  col6?: string
  col7?: string
  col8?: string
  col9?: string
  col10?: string
}

export interface GeneralTableProps {
  headers: string[]
  dataTable: DataTable[]
  title?: string
}

const GeneralTable = (props: GeneralTableProps) => {

  const { headers, dataTable, title } = props;
  
  const data: DataTable[] = React.useMemo(
    () => dataTable,
    [dataTable]
  );

  const columnsHeaders: Column[] = headers.map((header, index) => {

    const myAccessor = `col${index}` as const;

    return (
      {
        Header: header,
        accessor: myAccessor
      }
    );
  });

 
  const columns = React.useMemo(
    () => columnsHeaders,
    [columnsHeaders]
  );
 
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  const handleClickOnRow = (chartIndex: number) => {
    scrollTo(`${chartIndex}-chart-index`);
  };
 
  return (
    <div className="relative overflow-x-auto rounded-lg shadow-md">
      {title && (<div className='flex justify-center align-middle bg-gray-900'>
        <p className="py-2 text-sm text-white uppercase">
          {title}
        </p>
      </div>)}
      <table {...getTableProps()} className="w-full text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  align="center" 
                  className="py-2 bg-gray-200 text-grey-800"
                  key={index}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            
            return (
              <tr {...row.getRowProps()} key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" onClick={() => handleClickOnRow(index)}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      align='center'
                      className="px-6 py-4 font-medium cursor-pointer text-grey-700 dark:text-white whitespace-nowrap"
                      key={index}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default GeneralTable;