import { AvailableTicker } from 'components/Accumulator/AccumulatorComponent';
import { useState } from 'react';
import EthImage from 'assets/cryptos/ethPng.png';
import UsdcImage from 'assets/cryptos/usdcPng.png';

interface DropDownProps {
  ticker?: AvailableTicker
  onChangeToken: (token: AvailableTicker) => void
}

const DropDown = (props: DropDownProps) => {



  const { ticker, onChangeToken } = props; 

  const [isDropdownActivated, setIsDropdownActivated] = useState<boolean>(false);
    
  const handleDropdownActivation = () => {
    setIsDropdownActivated(!isDropdownActivated);
  };

  const tokensList = [
    AvailableTicker.FETH,
    AvailableTicker.FUSD
  ];


  const tickerToImage = {
    FETH: EthImage,
    FUSD: UsdcImage,
  };
  

  const getTokenLabelFromTicker = (ticker?: AvailableTicker) => {

    if (!ticker) return <p>Select token</p>;
    
    return (
      <div className='flex justify-between'>
        <div className='pr-2 m-auto '>
          {ticker}
        </div>
        <div>
          <img src={tickerToImage[ticker]} alt="token-icon" width={30} /> 
        </div>
      </div>
    );

  };


  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          onClick={handleDropdownActivation}
          className="inline-flex justify-center w-full px-4 py-2 text-xl bg-white border border-gray-300 rounded-md shadow-sm font-montserrat hover:bg-gray-50">
          {getTokenLabelFromTicker(ticker)}
          <svg className="w-5 h-5 m-auto ml-2 -mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
      </div>
      {isDropdownActivated && (
        <div className="absolute right-0 mt-2 origin-top-right bg-white rounded-md shadow-lg cursor-pointer ring-black ring-opacity-5 focus:outline-none ">
          <div className="py-1">
            {
              tokensList.map((token, index) => {
                return (
                  <div onClick={() => {onChangeToken(token), setIsDropdownActivated(false); }} className="block px-4 py-2 text-gray-700 hover:bg-bubble-gum font-xl font-montserrat" key={index}>
                    {getTokenLabelFromTicker(token)}
                  </div>
                );
              })
            }
          </div>
        </div>
      )}
    </div>
  );
};
  

export default DropDown;