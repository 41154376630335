import first_pair_daily_return from './ChartsData/first_pair_daily_return.json';
import second_pair_daily_return from './ChartsData/second_pair_daily_return.json';
import third_pair_daily_return from './ChartsData/third_pair_daily_return.json';
import first_pair_short_range_metrics from './ShortTermTableData/first_pair_short_range_metrics.json';
import second_pair_short_range_metrics from './ShortTermTableData/second_pair_short_range_metrics.json';
import third_pair_short_range_metrics from './ShortTermTableData/third_pair_short_range_metrics.json';
import first_pair_long_range_metrics from './LongTermTableData/first_pair_long_range_metrics.json';
import second_pair_long_range_metrics from './LongTermTableData/second_pair_long_range_metrics.json';
import third_pair_long_range_metrics from './LongTermTableData/third_pair_long_range_metrics.json';
import pairs_ranking from './RankingData/pairs_ranking.json';
import { ChartAndTablesLayout } from 'layouts/ChartAndTablesLayout';
import GeneralTable from 'components/GeneralTable/GeneralTable';


const firstTableHeaders = ['PAIR', 'MEDIAN FEES APY (%)',	'MEDIAN IMPERMANENT LOSS (%)'];
const firstTableData = [
  {
    col0: pairs_ranking.first_pair.name,
    col1: `${(first_pair_long_range_metrics.median_fees_apy * 100).toFixed(1)}%`,
    col2: `${(first_pair_long_range_metrics.median_IL_apy * 100).toFixed(1)}%`,
  },
  {
    col0: pairs_ranking.second_pair.name,
    col1: `${(second_pair_long_range_metrics.median_fees_apy * 100).toFixed(1)}%`,
    col2: `${(second_pair_long_range_metrics.median_IL_apy * 100).toFixed(1)}%`,
  },
  {
    col0: pairs_ranking.third_pair.name,
    col1: `${(third_pair_long_range_metrics.median_fees_apy * 100).toFixed(1)}%`,
    col2: `${(third_pair_long_range_metrics.median_IL_apy * 100).toFixed(1)}%`,
  },
];

const secondTableHeaders = ['PAIR', '7 DAYS VOLUME', '7 DAYS CHANGE', '30 DAYS CHANGE'];
const secondTableData = [
  {
    col0: pairs_ranking.first_pair.name,
    col1: `${(pairs_ranking.first_pair.seven_days_volume/1000000).toFixed(1)}m`,
    col2: `${(pairs_ranking.first_pair.one_week_volume_change*100).toFixed(1)}%`,
    col3: `${(pairs_ranking.first_pair.one_month_volume_change*100).toFixed(1)}%`
  },
  {
    col0: pairs_ranking.second_pair.name,
    col1: `${(pairs_ranking.second_pair.seven_days_volume/1000000).toFixed(1)}m`,
    col2: `${(pairs_ranking.second_pair.one_week_volume_change*100).toFixed(1)}%`,
    col3: `${(pairs_ranking.second_pair.one_month_volume_change*100).toFixed(1)}%`
  },
  {
    col0: pairs_ranking.third_pair.name,
    col1: `${(pairs_ranking.third_pair.seven_days_volume/1000000).toFixed(1)}m`,
    col2: `${(pairs_ranking.third_pair.one_week_volume_change*100).toFixed(1)}%`,
    col3: `${(pairs_ranking.third_pair.one_month_volume_change*100).toFixed(1)}%`
  },
];

const thirdTableHeaders = ['PAIR', 'SMALL TRADE SIZE',	'MEDIAN TRADE SIZE', 'LARGE TRADE SIZE'];
const thirdTableData = [
  {
    col0: pairs_ranking.first_pair.name,
    col1: `$${pairs_ranking.first_pair.small_trade_size.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1})}`,
    col2: `$${pairs_ranking.first_pair.median_trade_size.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1})}`,
    col3: `$${pairs_ranking.first_pair.large_trade_size.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1})}`
  },
  {
    col0: pairs_ranking.second_pair.name,
    col1: `$${pairs_ranking.second_pair.small_trade_size.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1})}`,
    col2: `$${pairs_ranking.second_pair.median_trade_size.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1})}`,
    col3: `$${pairs_ranking.second_pair.large_trade_size.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1})}`
  },
  {
    col0: pairs_ranking.third_pair.name,
    col1: `$${pairs_ranking.third_pair.small_trade_size.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1})}`,
    col2: `$${pairs_ranking.third_pair.median_trade_size.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1})}`,
    col3: `$${pairs_ranking.third_pair.large_trade_size.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1})}`
  },
];

const fourthTableHeaders = ['PAIR', 'TVL', '7 DAYS CHANGE', '30 DAYS CHANGE'];
const fourthTableData = [
  {
    col0: pairs_ranking.first_pair.name,
    col1: `$${(pairs_ranking.first_pair.tvl/1000000).toFixed(1)}m`,
    col2: `${(pairs_ranking.first_pair.one_week_tvl_change*100).toFixed(1)}%`,
    col3: `${(pairs_ranking.first_pair.one_month_tvl_change*100).toFixed(1)}%`
  },
  {
    col0: pairs_ranking.second_pair.name,
    col1: `$${(pairs_ranking.second_pair.tvl/1000000).toFixed(1)}m`,
    col2: `${(pairs_ranking.second_pair.one_week_tvl_change*100).toFixed(1)}%`,
    col3: `${(pairs_ranking.second_pair.one_month_tvl_change*100).toFixed(1)}%`
  },
  {
    col0: pairs_ranking.third_pair.name,
    col1: `$${(pairs_ranking.third_pair.tvl/1000000).toFixed(1)}m`,
    col2: `${(pairs_ranking.third_pair.one_week_tvl_change*100).toFixed(1)}%`,
    col3: `${(pairs_ranking.third_pair.one_month_tvl_change*100).toFixed(1)}%`
  },
];

const chartAndTables = [
  {
    pairTickers: pairs_ranking.first_pair.name,
    chartData: first_pair_daily_return,
    shortTermTableData: first_pair_short_range_metrics,
    longTermTableData: first_pair_long_range_metrics,
  },
  {
    pairTickers: pairs_ranking.second_pair.name,
    chartData: second_pair_daily_return,
    shortTermTableData: second_pair_short_range_metrics,
    longTermTableData: second_pair_long_range_metrics,
  },
  {
    pairTickers: pairs_ranking.third_pair.name,
    chartData: third_pair_daily_return,
    shortTermTableData: third_pair_short_range_metrics,
    longTermTableData: third_pair_long_range_metrics,
  },
];

const Analytics = () => {
  return (
    <div className="px-2 m-auto max-w-7xl">
      <div className="pt-4 pb-12">
        <div className="pb-6">
          <p className="text-4xl font-semibold text-center text-white font-montserrat">
            Uniswap V2 best risk adjusted pairs
          </p>
        </div>
        <div className='pb-4 lg:flex'>
          <div className='pb-4 lg:pb-0 lg:pr-4 lg:w-1/2'>
            <GeneralTable headers={firstTableHeaders} dataTable={firstTableData} />
          </div>
          <div className='lg:pl-4 lg:w-1/2'>
            <GeneralTable headers={secondTableHeaders} dataTable={secondTableData} />
          </div>
        </div>
        <div className='pt-4 lg:flex'>
          <div className='pb-4 lg:pb-0 lg:pr-4 lg:w-1/2'>
            <GeneralTable headers={thirdTableHeaders} dataTable={thirdTableData} />
          </div>
          <div className='lg:pl-4 lg:w-1/2'>
            <GeneralTable headers={fourthTableHeaders} dataTable={fourthTableData} />
          </div>
        </div>
      </div>
      <div className="">
        <div className="pb-5">
          <p className="text-4xl font-semibold text-center text-white font-montserrat">
            Pairs analytics
          </p>
        </div>
        {chartAndTables.map((chartAndTable, index) => {
          return (
            <div key={index} id={`${index}-chart-index`}>
              <ChartAndTablesLayout
                pairTickers={chartAndTable.pairTickers}
                chartData={chartAndTable.chartData}
                shortTermTableData={Object.assign(chartAndTable.shortTermTableData, {range: '30 days rolling window'})}
                longTermTableData={Object.assign(chartAndTable.longTermTableData, {range: '6 months rolling windows'})}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Analytics;
