import { Banner } from '../../sections/Banner';
import { Description } from '../../sections/Description';
import { Partners } from '../../sections/Partners';

const Main = () => {

  return (
    <>
      <div className="px-4">
        <Banner />
      </div>
      <div className="px-4">
        <Description />
      </div>
      <div className="px-4">
        <Partners />
      </div>
    </>
  );
};

export default Main;
