export enum AvailableChain {
  ArbitrumRinkeby = 'Arbitrum Rinkeby',
  NetworkNotSupported = 'Network not supported'
}


const getNetworkNameFromChainId = (chainId: number): AvailableChain => {
  switch(chainId) {
  //case 1:
  //  return 'Ethereum  mainnet';
  //case 3:
  //  return 'Ropsten testnet';
  //case 4:
  //  return 'Rinkeby testnet';
  //case 42161:
  //  return 'Arbitrum One';
  case 421611:
    return AvailableChain.ArbitrumRinkeby;
  default:
    return AvailableChain.NetworkNotSupported;
  }
};

export default getNetworkNameFromChainId;
