// import { useState } from 'react';
// import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
// import whitepaperPDF from 'assets/whitepaper/whitepaper.pdf';
// import { ReactComponent as LeftArrowSvg } from './LeftArrowSvg.svg';
// import { ReactComponent as RightArrowSvg } from './RightArrowSvg.svg';
// import { ReactComponent as DownloadSvg } from './DownloadSvg.svg';
// import { Link } from 'react-router-dom';

// const Whitepaper = () => {
//   const [pageNumber, setPageNumber] = useState(1);

//   const handleLeftArrow = () => {
//     if (pageNumber > 1) {
//       setPageNumber(pageNumber - 1);
//     }
//   };

//   const handleRightArrow = () => {
//     if (pageNumber < 11) {
//       setPageNumber(pageNumber + 1);
//     }
//   };

//   return (
//     <div className="h-screen gradient">
//       <div className="py-10">
//         <div className="flex justify-center">
//           <div>
//             <Link to={whitepaperPDF} target="_blank" download>
//               <div className="flex justify-center">
//                 <div>
//                   <p className="pb-4 pr-4 text-xl font-semibold text-white cursor-pointer font-montserrat hover:underline">
//                     Download whitepaper
//                   </p>
//                 </div>
//                 <div>
//                   <DownloadSvg />
//                 </div>
//               </div>
//             </Link>
//           </div>
//         </div>
//         <div className="flex justify-center align-middle">
//           <div className="hidden h-screen pr-2 lg:flex">
//             <div className="m-auto" onClick={handleLeftArrow}>
//               <LeftArrowSvg />
//             </div>
//           </div>
//           <div className="react-pdf__Page__canvas">
//             <Document file={whitepaperPDF} onLoadError={console.error}>
//               <Page pageNumber={pageNumber} className="w-1/3 h-1/3" />
//             </Document>
//           </div>
//           <div className="hidden h-screen pl-2 lg:flex">
//             <div className="m-auto" onClick={handleRightArrow}>
//               <RightArrowSvg />
//             </div>
//           </div>
//         </div>
//         <div className="flex justify-center lg:hidden">
//           <div>
//             <div onClick={handleLeftArrow}>
//               <LeftArrowSvg />
//             </div>
//           </div>
//           <div>
//             <div onClick={handleRightArrow}>
//               <RightArrowSvg />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Whitepaper;

const Whitepaper = () => {
  return <p>Whitepaper</p>;
};

export default Whitepaper;
