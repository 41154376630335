import { useState } from 'react';
import faunadb from 'faunadb';
import validateEmail from 'utils/validateEmail';
const client = new faunadb.Client({
  secret: process.env.REACT_APP_FAUNA_DB || '',
  scheme: 'https',
  domain: 'db.fauna.com',
});

const q = faunadb.query;



const Banner = () => {

  const [email, setEmail] = useState<string | undefined>(undefined);
  const [isSubscribeSent, setIsSubscribeSent] = useState<boolean>(false);
  const [isSubscribeFailed, setIsSubscribeFailed] = useState<boolean>(false);

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleClickSubscribe = () => {
    if (email) {
      createSubscribeMessage(email);
    }

  };
  
  const messageSentSuccessfully = () => {
    setIsSubscribeSent(true);
    setEmail(undefined);
  };

  const createSubscribeMessage = (email: string) => {
    client
      .query(
        q.Create(q.Collection('SubscribeForm'), {
          data: {
            email: email
          }
        })
      )
      .then(() => messageSentSuccessfully())
      .catch(() => setIsSubscribeFailed(true));
  };

  return (
    <div>
      <div className="flex justify-center pt-4 pb-20 lg:pt-32">
        <div className="max-w-5xl pt-10">
          <div className="pt-10 pb-12 border-2 rounded-xl">
            <p className="mx-12 text-5xl font-semibold text-center text-white font-montserrat lg:text-8xl">
              <span className='text-bubble-gum-saturated'>Akiu</span>mulate crypto
            </p>
          </div>
          <div className="pt-10 pb-4">
            <p className="text-3xl text-center text-white font-montserrat">
              Earning yield in DeFi made simple
            </p>
          </div>
        </div>
      </div>
      <div className="pb-10">
        <div className="flex justify-center">
          <div className="justify-center pb-2 lg:flex">
            <div className="flex px-4 align-middle">
              <input
                onChange={(e) => handleChangeEmail(e)}
                className="px-2 py-2 text-xl text-gray-800 rounded-xl inuput font-montserrat focus:outline-none "
                placeholder="name@email.com"
              />
            </div>
            <div className="flex justify-center pt-2 lg:pt-0">
              <button
                onClick={() => handleClickSubscribe()}
                disabled={!validateEmail(email)}
                className="px-4 py-2 font-semibold text-white border cursor-pointer bg-bubble-gum-saturated font-montserrat rounded-2xl font-3xl hover:bg-blue-400"
              >
                  Subscribe
              </button>
  
            </div>
          </div>
        </div>
        <div className='flex justify-center'>
          {isSubscribeSent && (
            <div>
              <p className='text-green-700 font-3xl'>Subscribed!</p>
            </div>
          )}
          {isSubscribeFailed && (
            <div>
              <p className='text-red-700 font-3xl'>Subscribe error, try again</p>
            </div>)}
        </div>
      </div>
      <div className="flex justify-center pb-30">
        <div className="max-w-6xl">
          <div className="">
            <div className="w-32 border-t-2 border-gray-200 lg:w-96" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
