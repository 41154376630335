import { ReactNode } from 'react';
import { ReactComponent as AnalyticsSvg } from './AnalyticsSvg.svg';
import { ReactComponent as InstitutionSvg } from './InstitutionSvg.svg';
import { ReactComponent as IntegrateSvg } from './IntegrateSvg.svg';
import { ReactComponent as YieldSvg } from './YieldSvg.svg';
import { useNavigate } from 'react-router-dom';
import { AvailablePage } from 'utils/availablePages';

const Description = () => {
  const navigate = useNavigate();

  interface DescriptionInfosProps {
    title: string;
    content: string;
    imageSvg: ReactNode;
    buttonText: string
    onClickCardLink: () => void;
  }

  const handleClickAnalytics = () => {
    navigate(AvailablePage.Analytics);
  };

  const handleClickAcculator = () => {
    navigate(AvailablePage.Accumulator);

  };
  
  const handleClickContact = () => {
    navigate(AvailablePage.Contact);
  };

  const handleClickInstitutions = () => {
    navigate(AvailablePage.Institutions);
  };

  const descriptionsInfos: DescriptionInfosProps[] = [
    {
      title: 'YIELD FOR EVERYONE',
      content: 'Launch the app, connect your wallet, earn - it\'s that simple!',
      imageSvg: <YieldSvg />,
      buttonText: 'Launch app',
      onClickCardLink: () => handleClickAcculator()
    },
    {
      title: 'ANALYTICS',
      content: 'Charts and data to track your performance',
      imageSvg: <AnalyticsSvg />,
      buttonText: 'Analytics',
      onClickCardLink: () => handleClickAnalytics()
    },
    {
      title: 'INSTITUTIONS',
      content: 'Leverage our infrastructure to access opportunities in DeFi',
      imageSvg: <InstitutionSvg />,
      buttonText: 'Info',
      onClickCardLink: () => handleClickInstitutions()
    },
    {
      title: 'INTEGRATION',
      content: 'Connect your DApp to our contracts',
      imageSvg: <IntegrateSvg />,
      buttonText: 'Contact us',
      onClickCardLink: () => handleClickContact()
    },
  ];

  const DescriptionCard = (props: DescriptionInfosProps) => {
    const { title, content, imageSvg, buttonText, onClickCardLink } = props;

    return (
      <div className="flex flex-col justify-between h-full px-8 py-8 mb-4 rounded bg-dark-blue">
        <div className="flex justify-center">{imageSvg}</div>
        <div>
          <p className="text-xl font-semibold text-center text-white font-montserrat">
            {title}
          </p>
        </div>
        <div className="pt-2 pb-4">
          <p className="text-center text-white font-montserrat text-l">
            {content}
          </p>
        </div>
        {onClickCardLink && <div className="flex justify-center">
          <button
            className="w-full py-2 font-semibold rounded text-dark-blue bg-bubble-gum-saturated hover:bg-blue-400"
            onClick={() => onClickCardLink()}
          >
            {buttonText}
          </button>
        </div> }
      </div>
    );
  };

  return (
    <div className="flex justify-center pt-8 mb-10" id="benefits">
      <div className="max-w-4xl">
        <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
          {descriptionsInfos.map((descriptionInfo, index) => {
            return (
              <div key={index}>
                <DescriptionCard
                  title={descriptionInfo.title}
                  content={descriptionInfo.content}
                  imageSvg={descriptionInfo.imageSvg}
                  onClickCardLink={descriptionInfo.onClickCardLink}
                  buttonText={descriptionInfo.buttonText}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Description;
