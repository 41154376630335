
interface InputData {
  [key: string]: number 
}

interface OutputData {
  x: string
  y: number
}

const getDataForTwoLinesChart = (inputData: InputData): OutputData[] => {

  const finalDataset = Object.keys(inputData).map((objKey) => {
    return {
      x: new Date(parseInt(objKey,10)).toLocaleDateString('en-US'),
      y: inputData[objKey] * 100
    };
    
  });
  
  return finalDataset;
};

export default getDataForTwoLinesChart;

